var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: { id: "body", "element-loading-text": "努力傳送資料中..." }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == "1",
              expression: "step == '1'"
            }
          ],
          attrs: { id: "step-one" }
        },
        [
          _c("ChequeDonationInfo", {
            on: {
              nextStep: function($event) {
                return _vm.setChequeDonationInfo($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == "2",
              expression: "step == '2'"
            }
          ],
          attrs: { id: "step-two" }
        },
        [
          _c("ChequeInfo", {
            on: {
              nextStep: function($event) {
                return _vm.setChequeInfo($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == "3",
              expression: "step == '3'"
            }
          ],
          attrs: { id: "step-three" }
        },
        [
          _vm.bwbcChequeDonate.donatorTypeCode == "PERSON"
            ? _c("MemberInfo", {
                attrs: { donationInfo: _vm.bwbcChequeDonate },
                on: {
                  nextStep: function($event) {
                    return _vm.setMemberInfo($event)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.bwbcChequeDonate.donatorTypeCode == "CORPORATION"
            ? _c("CompanyInfo", {
                attrs: { donationInfo: _vm.bwbcChequeDonate },
                on: {
                  nextStep: function($event) {
                    return _vm.setCorporationInfo($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.step == "4"
        ? _c("div", [
            _c(
              "span",
              {
                staticStyle: {
                  "font-weight": "500",
                  "font-size": "20px",
                  color: "#9c8044"
                }
              },
              [_vm._v("感謝您的支持")]
            ),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("div", [
              _c(
                "span",
                { staticStyle: { "font-size": "18px", color: "#9c8044" } },
                [_vm._v("以下是您回報的資訊：")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "16px",
                    margin: "0",
                    color: "#424242"
                  }
                },
                [_vm._v("\n        捐款方式：支票捐款\n      ")]
              ),
              _vm._v(" "),
              _vm.bwbcChequeDonate.donatorTypeCode == "PERSON"
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        margin: "0",
                        color: "#424242"
                      }
                    },
                    [
                      _vm._v(
                        "\n        捐款人：" +
                          _vm._s(_vm.bwbcChequeDonate.name) +
                          "\n      "
                      )
                    ]
                  )
                : _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        margin: "0",
                        color: "#424242"
                      }
                    },
                    [
                      _vm._v(
                        "\n        捐款公司：" +
                          _vm._s(_vm.bwbcChequeDonate.companyName) +
                          "\n      "
                      )
                    ]
                  ),
              _vm._v(" "),
              _vm.bwbcChequeDonate.donatorTypeCode == "PERSON"
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        margin: "0",
                        color: "#424242"
                      }
                    },
                    [
                      _vm._v(
                        "\n        手機號碼：" +
                          _vm._s(_vm.bwbcChequeDonate.cellPhone) +
                          "\n      "
                      )
                    ]
                  )
                : _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        margin: "0",
                        color: "#424242"
                      }
                    },
                    [
                      _vm._v(
                        "\n        聯絡電話：" +
                          _vm._s(_vm.bwbcChequeDonate.cellPhone) +
                          "\n      "
                      )
                    ]
                  ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "16px",
                    margin: "0",
                    color: "#424242"
                  }
                },
                [_vm._v("\n        捐款項目：福智佛教學院\n      ")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "16px",
                    margin: "0",
                    color: "#424242"
                  }
                },
                [
                  _vm._v(
                    "\n        回報日期：" + _vm._s(_vm.donaDate) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "16px",
                    margin: "0",
                    color: "#424242"
                  }
                },
                [
                  _vm._v(
                    "\n        捐款金額：" +
                      _vm._s(_vm.bwbcChequeDonate.amount) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.bwbcChequeDonate.receiptTypeCode == "BY_TIME",
                      expression:
                        "bwbcChequeDonate.receiptTypeCode == 'BY_TIME'"
                    }
                  ],
                  staticStyle: {
                    "font-size": "16px",
                    margin: "0",
                    color: "#424242"
                  }
                },
                [_vm._v("\n        收據開立方式：單筆開立\n      ")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.bwbcChequeDonate.receiptTypeCode == "UNWANTTED",
                      expression:
                        "bwbcChequeDonate.receiptTypeCode == 'UNWANTTED'"
                    }
                  ],
                  staticStyle: {
                    "font-size": "16px",
                    margin: "0",
                    color: "#424242"
                  }
                },
                [_vm._v("\n        收據開立方式：不需寄發\n      ")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "16px",
                    margin: "16px 0px",
                    color: "#9e1818",
                    "font-weight": "500"
                  }
                },
                [
                  _vm._v(
                    "\n        敬請填妥新臺幣支票抬頭「福智學校財團法人」，請註明禁止背書轉讓，將本同意書及支票掛號郵寄至「10553台北市松山區南京東路四段152號10樓\n        福智佛教學院籌備處」。\n      "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "p",
              { staticStyle: { "font-size": "16px", color: "#424242" } },
              [_vm._v("再次誠摯感謝您！")]
            ),
            _vm._v(" "),
            _vm._m(1)
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "dialog-message-box",
            title: _vm.dialog.title,
            visible: _vm.dialog.isShow,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog, "isShow", $event)
            }
          }
        },
        [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.dialog.content) } }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-row",
                { staticClass: "top-line" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "primary-color",
                          on: {
                            click: function($event) {
                              _vm.dialog.isShow = false
                            }
                          }
                        },
                        [_vm._v("我知道了")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "font-size": "16px", color: "#424242" } }, [
      _vm._v(
        "\n      謝謝您認同我們的教育理念，更為了教育環境盡一份力！\n      "
      ),
      _c("br"),
      _vm._v("若捐款有問題我們將主動跟您聯繫。\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "font-size": "16px", color: "#424242" } }, [
      _vm._v("\n      若您有疑惑，歡迎您透過以下方式聯繫我們：\n      "),
      _c("br"),
      _vm._v("籌備處募款組辦公室電話02-7730-0016\n      "),
      _c("br"),
      _vm._v("福智佛教學院籌備處信箱：bwbc.po@blisswisdom.org\n      "),
      _c("br")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }