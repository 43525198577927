var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "金鑰" } },
            [
              _c("el-input", {
                attrs: { placeholder: "產品金鑰" },
                model: {
                  value: _vm.api_key,
                  callback: function($$v) {
                    _vm.api_key = $$v
                  },
                  expression: "api_key"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }