var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "companyInfo",
          attrs: { model: _vm.companyInfo, rules: _vm.rules }
        },
        [
          _c(
            "el-row",
            { staticClass: "step" },
            [
              _c(
                "el-steps",
                { attrs: { active: 3, "finish-status": "success" } },
                [
                  _c("el-step", {
                    attrs: { title: "金額", icon: "el-icon-s-order" }
                  }),
                  _vm._v(" "),
                  _vm.donationInfo.from == "card"
                    ? _c("el-step", {
                        attrs: { title: "卡號", icon: "el-icon-bank-card" }
                      })
                    : _vm.donationInfo.from == "transfer"
                    ? _c("el-step", {
                        attrs: { title: "匯款", icon: "el-icon-bank-card" }
                      })
                    : _c("el-step", {
                        attrs: { title: "支票", icon: "el-icon-bank-card" }
                      }),
                  _vm._v(" "),
                  _c("el-step", {
                    attrs: { title: "收據", icon: "el-icon-s-custom" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticStyle: {
                color: "#9c8044",
                "font-weight": "500",
                "font-size": "24px"
              }
            },
            [_vm._v("公司資訊")]
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("span", { staticClass: "sub-title" }, [_vm._v("公司名稱")]),
                _vm._v(" "),
                _c("span", { staticClass: "required-mark" }, [_vm._v("*")]),
                _vm._v(" "),
                _c("span", { staticClass: "sub-title" }, [_vm._v(":")])
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "請輸入公司名稱",
                          maxlength: "10"
                        },
                        model: {
                          value: _vm.companyInfo.name,
                          callback: function($$v) {
                            _vm.$set(_vm.companyInfo, "name", $$v)
                          },
                          expression: "companyInfo.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("span", { staticClass: "sub-title" }, [_vm._v("統一編號")]),
                _vm._v(" "),
                _c("span", { staticClass: "required-mark" }, [_vm._v("*")]),
                _vm._v(" "),
                _c("span", { staticClass: "sub-title" }, [_vm._v(":")])
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "sinCompany" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "請輸入統一編號",
                          maxlength: "8",
                          minlength: "8"
                        },
                        model: {
                          value: _vm.companyInfo.sinCompany,
                          callback: function($$v) {
                            _vm.$set(_vm.companyInfo, "sinCompany", $$v)
                          },
                          expression: "companyInfo.sinCompany"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("span", { staticClass: "sub-title" }, [
                  _vm._v("公司聯絡電子信箱")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "required-mark" }, [_vm._v("*")]),
                _vm._v(" "),
                _c("span", { staticClass: "sub-title" }, [_vm._v(":")])
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "contactEmail" } },
                    [
                      _c("el-input", {
                        attrs: { type: "email", placeholder: "請輸入電子信箱" },
                        model: {
                          value: _vm.companyInfo.contactEmail,
                          callback: function($$v) {
                            _vm.$set(_vm.companyInfo, "contactEmail", $$v)
                          },
                          expression: "companyInfo.contactEmail"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("span", { staticClass: "sub-title" }, [_vm._v("收據抬頭")]),
                _vm._v(" "),
                _c("span", { staticClass: "required-mark" }, [_vm._v("*")]),
                _vm._v(" "),
                _c("span", { staticClass: "sub-title" }, [_vm._v(":")])
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "donatorName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "請輸入姓名(僅限填寫一位)",
                          "validate-event": true,
                          maxlength: "10"
                        },
                        model: {
                          value: _vm.companyInfo.donatorName,
                          callback: function($$v) {
                            _vm.$set(_vm.companyInfo, "donatorName", $$v)
                          },
                          expression: "companyInfo.donatorName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("span", { staticClass: "sub-title" }, [
                  _vm._v("公司聯絡地址")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "required-mark" }, [_vm._v("*")]),
                _vm._v(" "),
                _c("span", { staticClass: "sub-title" }, [
                  _vm._v("(收據寄送地址):")
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("AddressEdit", {
            ref: "addressEdit",
            attrs: { oAddress: _vm.companyInfo.companyAddress }
          }),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("el-col", [
                _c("span", { staticClass: "sub-title" }, [
                  _vm._v("聯絡人姓名")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "required-mark" }, [_vm._v("*")]),
                _vm._v(" "),
                _c("span", { staticClass: "sub-title" }, [_vm._v(":")]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "el-icon-question",
                  staticStyle: {
                    color: "#9c8044",
                    "font-size": "14px",
                    cursor: "pointer"
                  },
                  on: {
                    click: function($event) {
                      return _vm.showMessageBox(
                        "提示",
                        "請提供聯繫人的資訊。對於捐款若有任何問題，我們會主動聯絡該聯繫人。"
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "contactName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "請輸入聯絡人姓名"
                        },
                        model: {
                          value: _vm.companyInfo.contactName,
                          callback: function($$v) {
                            _vm.$set(_vm.companyInfo, "contactName", $$v)
                          },
                          expression: "companyInfo.contactName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("span", { staticClass: "sub-title" }, [_vm._v("聯絡電話")]),
                _vm._v(" "),
                _c("span", { staticClass: "required-mark" }, [_vm._v("*")]),
                _vm._v(" "),
                _c("span", { staticClass: "sub-title" }, [_vm._v(":")])
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "contactPhone" } },
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.companyInfo.contactPhone,
                          callback: function($$v) {
                            _vm.$set(_vm.companyInfo, "contactPhone", $$v)
                          },
                          expression: "companyInfo.contactPhone"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card", attrs: { shadow: "never" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          staticStyle: { "text-align": "center" },
                          attrs: { slot: "header" },
                          slot: "header"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "sub-title",
                              staticStyle: { color: "white" }
                            },
                            [_vm._v("捐款注意事項")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 2 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "isAcceptPdpa" } },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.isAcceptPdpa,
                                        expression: "isAcceptPdpa"
                                      }
                                    ],
                                    attrs: { type: "checkbox", required: "" },
                                    domProps: {
                                      checked: Array.isArray(_vm.isAcceptPdpa)
                                        ? _vm._i(_vm.isAcceptPdpa, null) > -1
                                        : _vm.isAcceptPdpa
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.isAcceptPdpa,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.isAcceptPdpa = $$a.concat([
                                                $$v
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.isAcceptPdpa = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.isAcceptPdpa = $$c
                                        }
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "margin-top": "8px" },
                              attrs: { span: 22 }
                            },
                            [
                              _c("label", { staticClass: "notice" }, [
                                _vm._v(
                                  "\n                我同意遵守本網站個人\n                "
                                ),
                                _c(
                                  "a",
                                  {
                                    staticStyle: { color: "#2B63E0" },
                                    attrs: {
                                      href:
                                        "https://bwbc.blisswisdom.org/%e3%80%90%e6%8d%90%e6%ac%be%e6%b3%a8%e6%84%8f%e4%ba%8b%e9%a0%85%e3%80%91/",
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v("捐款注意事項")]
                                ),
                                _vm._v(
                                  "及其他有關著作權、版權、商標專用權、網路智慧財產權等之法律規定。\n              "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            "background-color": "#F0F0F0",
                            "margin-top": "20px"
                          }
                        },
                        [
                          _c("el-col", { staticStyle: { padding: "16px" } }, [
                            _c("label", { staticClass: "notice" }, [
                              _vm._v(
                                "\n                依財團法人法第25條規定，除捐贈者事先書面表示反對外，各財團法人均需主動公開捐贈者之「姓名」及「捐款金額」，詳見\n                "
                              ),
                              _c(
                                "a",
                                {
                                  staticStyle: { color: "#2B63E0" },
                                  attrs: {
                                    href:
                                      "https://www.blisswisdom.org/donate/qanda#q2",
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("捐款徵信說明")]
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticStyle: {
                "background-color": "#d8ba5f33",
                padding: "8px 16px 0",
                "border-radius": "4px",
                border: "solid 1px #decb93",
                "margin-top": "24px"
              }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "acceptDeclaration" } },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.acceptDeclaration,
                          callback: function($$v) {
                            _vm.acceptDeclaration = $$v
                          },
                          expression: "acceptDeclaration"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticStyle: { "padding-top": "4px" }, attrs: { span: 22 } },
                [
                  _c("span", { staticClass: "sub-title" }, [
                    _vm._v("\n          請詳閱\n          "),
                    _c(
                      "a",
                      {
                        staticStyle: {
                          color: "blue",
                          "text-decoration": "none"
                        },
                        on: {
                          click: function($event) {
                            _vm.declaration = true
                          }
                        }
                      },
                      [_vm._v("《個資聲明》")]
                    ),
                    _vm._v(
                      "\n          ，進行下一步即表示同意個資聲明。\n        "
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "text-align": "center", margin: "16px 0" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "scroll-to",
                          rawName: "v-scroll-to",
                          value: "#step-two",
                          expression: "'#step-two'"
                        }
                      ],
                      on: {
                        click: function($event) {
                          return _vm.previous()
                        }
                      }
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "scroll-to",
                          rawName: "v-scroll-to",
                          value: "#step-one",
                          expression: "'#step-one'"
                        }
                      ],
                      on: {
                        click: function($event) {
                          return _vm.submitForm("companyInfo")
                        }
                      }
                    },
                    [_vm._v("送出")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "dialog-message-box",
            title: _vm.dialog.title,
            visible: _vm.dialog.isShow,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog, "isShow", $event)
            }
          }
        },
        [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.dialog.content) } }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-row",
                { staticClass: "top-line" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "primary-color",
                          on: {
                            click: function($event) {
                              _vm.dialog.isShow = false
                            }
                          }
                        },
                        [_vm._v("我知道了")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "個人資料處理暨使用同意書",
            visible: _vm.declaration,
            width: "80%",
            "custom-class": "dialog-message-box"
          },
          on: {
            "update:visible": function($event) {
              _vm.declaration = $event
            }
          }
        },
        [
          _c(
            "el-container",
            [
              _c("el-main", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "infinite-scroll",
                        rawName: "v-infinite-scroll",
                        value: _vm.load,
                        expression: "load"
                      }
                    ],
                    staticClass: "infinite-list",
                    staticStyle: {
                      overflow: "auto",
                      height: "40vh",
                      "background-color": "whitesmoke",
                      padding: "16px"
                    }
                  },
                  [
                    _c("p", { staticStyle: { "font-size": "16px" } }, [
                      _vm._v(
                        "\n            立同意書人" +
                          _vm._s(_vm.companyInfo.contactName) +
                          "，茲同意福智佛教學院籌備處\n            (以下簡稱「籌備處」)及福智傳心(以下簡稱「傳心」)，因辦理與其設立宗旨相關之業務(以下稱「相關業務」)，需蒐集、處理及使用本人之個人資料，本人充分瞭解並同意以下為籌備處及傳心蒐集、處理、使用及保護本人個人資訊之方式:\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v("一、個人資料適用範圍:")]),
                    _vm._v(" "),
                    _c("p", { staticStyle: { "font-size": "16px" } }, [
                      _vm._v(
                        "\n            籌備處及傳心為辦理相關業務，得於必要範圍內取得本人之個人資料，使用的範圍包括但不限於上課點名、學員登錄及驗證、服務及活動訊息通知、活動報名等用途，及籌備處及傳心其他隱私權保護政策所規範之使用方式。\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v("二、個人資料之蒐集及使用:")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            (一)\n            依前項所述，本人同意提供予籌備處及傳心以下個人資料:姓名、身分字號、出生年月日、學經歷、服務單位/就讀學校、職稱、聯絡方式(電話、通訊/戶籍地址及e-mail)、背景介紹等及其他可以直接或間接方式識別個人之資料。\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            (二)\n            本人瞭解得依本人意願決定是否提供籌備處及傳心相關個人資料，惟本人所提供之個人資料不完整時，籌備處及傳心得拒絕提供必要之服務。\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            (三)\n            本人所提供之資料，僅在法令許可之範圍下，及相關業務範圍內暨本人及第三條所定之期間，以電子檔或紙本形式存於籌備處及傳心，提供予籌備處及傳心使用。及因上述目的之作業所需要時，得由籌備處及傳心委託之第三方於上述條件下作為處理及使用。\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            (四)\n            籌備處及傳心依個人資料保護法第八條第二項規定，有下列情事者，於蒐集及使用本人之個人資料時，得免告知本人:\n            1. 依法律規定得免告知。 2.\n            個人資料之蒐集係公務機關執行法定職務或非公務機關履行法定義務所必要。\n            3. 告知將妨害公務機關執行法定職務。 4.\n            告知將妨害第三人之重大利益。 5. 當事人明知應告知之內容。\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("三、個人資料使用期間、地區、對象及利用方式:")
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            (一)\n            使用期限:本人同意籌備處及傳心得使用本人所提供之個人資料之期間自本同意書簽署之當日起，至特定目的消失後十五年為止。\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            (二)\n            使用地區:本人之個人資料將於台灣、金門、澎湖、馬祖地區及國際間作利用。\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            (三)\n            使用對象:本人所提供之個人資料，限於籌備處及傳心及其所屬福智團體之相關法人，於相關必要之服務範圍內，非以營利目的之利用，福智寺並應採取適當之安全措施予以保護。\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            (四)\n            使用方式:本人之個人資料僅在法令許可及本人同意使用之範圍，及同意使用之期間內，於特定目的範圍內，非以營利目的之處理與使用。\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v("四、本人之權益事項:")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            籌備處及傳心於使用本人之個人資料時，本人得依據個人資料保護法第三條，向籌備處及傳心就本人之資料作以下請求:\n            (一) 查詢或請求閱覽。 (二) 製給複製本。 (三) 補充或更正。 (四)\n            停止蒐集、處理及利用。 (五) 刪除。\n          "
                      )
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "el-footer",
                { staticStyle: { height: "fit-content" } },
                [
                  _c(
                    "el-row",
                    { staticStyle: { margin: "0" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 2 } },
                        [
                          _c("el-checkbox", {
                            model: {
                              value: _vm.acceptDeclaration,
                              callback: function($$v) {
                                _vm.acceptDeclaration = $$v
                              },
                              expression: "acceptDeclaration"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c("p", [
                          _vm._v(
                            "\n              本人已確實詳閱並瞭解上述之內容，同意本籌備處及傳心以上述方式蒐集、處理及非以營利目的之利用本人個人資料並將本人個人資料建檔。\n              "
                          ),
                          _c("b", { staticStyle: { color: "red" } }, [
                            _vm._v("請勾選才能進行下一步")
                          ])
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.declaration = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "scroll-to",
                          rawName: "v-scroll-to",
                          value: "#card",
                          expression: "'#card'"
                        }
                      ],
                      attrs: { disabled: !_vm.acceptDeclaration },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("memberInfo")
                        }
                      }
                    },
                    [_vm._v("送出")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }