var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: { id: "body", "element-loading-text": "努力傳送資料中..." }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == "1",
              expression: "step == '1'"
            }
          ],
          attrs: { id: "step-one" }
        },
        [
          _c("TransferDonation", {
            on: {
              nextStep: function($event) {
                return _vm.setTransferDonation($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == "2",
              expression: "step == '2'"
            }
          ],
          attrs: { id: "step-two" }
        },
        [
          _c("TransferInfo", {
            on: {
              nextStep: function($event) {
                return _vm.setTransferInfo($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == "3",
              expression: "step == '3'"
            }
          ],
          attrs: { id: "step-three" }
        },
        [
          _vm.bwbcTransferDonate.donatorTypeCode == "PERSON"
            ? _c("MemberInfo", {
                attrs: { donationInfo: _vm.bwbcTransferDonate },
                on: {
                  nextStep: function($event) {
                    return _vm.setMemberInfo($event)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.bwbcTransferDonate.donatorTypeCode == "CORPORATION"
            ? _c("CompanyInfo", {
                attrs: { donationInfo: _vm.bwbcTransferDonate },
                on: {
                  nextStep: function($event) {
                    return _vm.setCorporationInfo($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.step == "4"
        ? _c("div", [
            _c(
              "span",
              {
                staticStyle: {
                  "font-weight": "500",
                  "font-size": "20px",
                  color: "#9c8044"
                }
              },
              [_vm._v("感謝您的支持")]
            ),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("div", [
              _c(
                "span",
                { staticStyle: { "font-size": "18px", color: "#9c8044" } },
                [_vm._v("以下是您回報的資訊：")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "16px",
                    margin: "0",
                    color: "#424242"
                  }
                },
                [_vm._v("捐款方式：匯款")]
              ),
              _vm._v(" "),
              _vm.bwbcTransferDonate.donatorTypeCode == "PERSON"
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        margin: "0",
                        color: "#424242"
                      }
                    },
                    [_vm._v("捐款人：" + _vm._s(_vm.bwbcTransferDonate.name))]
                  )
                : _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        margin: "0",
                        color: "#424242"
                      }
                    },
                    [
                      _vm._v(
                        "捐款公司：" +
                          _vm._s(_vm.bwbcTransferDonate.companyName)
                      )
                    ]
                  ),
              _vm._v(" "),
              _vm.bwbcTransferDonate.donatorTypeCode == "PERSON"
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        margin: "0",
                        color: "#424242"
                      }
                    },
                    [
                      _vm._v(
                        "手機號碼：" + _vm._s(_vm.bwbcTransferDonate.cellPhone)
                      )
                    ]
                  )
                : _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        margin: "0",
                        color: "#424242"
                      }
                    },
                    [
                      _vm._v(
                        "聯絡電話：" + _vm._s(_vm.bwbcTransferDonate.cellPhone)
                      )
                    ]
                  ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "16px",
                    margin: "0",
                    color: "#424242"
                  }
                },
                [_vm._v("捐款項目：福智佛教學院")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "16px",
                    margin: "0",
                    color: "#424242"
                  }
                },
                [_vm._v("回報日期：" + _vm._s(_vm.donaDate))]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "16px",
                    margin: "0",
                    color: "#424242"
                  }
                },
                [_vm._v("捐款金額：" + _vm._s(_vm.bwbcTransferDonate.amount))]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.bwbcTransferDonate.receiptTypeCode == "BY_TIME",
                      expression:
                        "bwbcTransferDonate.receiptTypeCode == 'BY_TIME'"
                    }
                  ],
                  staticStyle: {
                    "font-size": "16px",
                    margin: "0",
                    color: "#424242"
                  }
                },
                [_vm._v("收據開立方式：單筆開立")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.bwbcTransferDonate.receiptTypeCode == "UNWANTTED",
                      expression:
                        "bwbcTransferDonate.receiptTypeCode == 'UNWANTTED'"
                    }
                  ],
                  staticStyle: {
                    "font-size": "16px",
                    margin: "0",
                    color: "#424242"
                  }
                },
                [_vm._v("收據開立方式：不需寄發")]
              )
            ]),
            _vm._v(" "),
            _c(
              "p",
              { staticStyle: { "font-size": "16px", color: "#424242" } },
              [_vm._v("再次誠摯感謝您！")]
            ),
            _vm._v(" "),
            _vm._m(1)
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "font-size": "16px", color: "#424242" } }, [
      _vm._v(
        "\n      謝謝您認同我們的教育理念，更為了教育環境盡一份力！\n      "
      ),
      _c("br"),
      _vm._v("若捐款有問題我們將主動跟您聯繫。\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "font-size": "16px", color: "#424242" } }, [
      _vm._v("\n      若您有疑惑，歡迎您透過以下方式聯繫我們：\n      "),
      _c("br"),
      _vm._v("籌備處募款組辦公室電話02-7730-0016\n      "),
      _c("br"),
      _vm._v("福智佛教學院籌備處信箱：bwbc.po@blisswisdom.org\n      "),
      _c("br")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }