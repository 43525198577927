var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "transferDonation",
          attrs: { model: _vm.transferDonation, rules: _vm.rules }
        },
        [
          _c(
            "el-row",
            { staticClass: "step" },
            [
              _c(
                "el-steps",
                { attrs: { active: 1, "finish-status": "success" } },
                [
                  _c("el-step", {
                    attrs: { title: "金額", icon: "el-icon-tickets" }
                  }),
                  _vm._v(" "),
                  _c("el-step", {
                    attrs: { title: "匯款", icon: "el-icon-s-order" }
                  }),
                  _vm._v(" "),
                  _c("el-step", {
                    attrs: { title: "收據", icon: "el-icon-s-custom" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticStyle: {
                color: "#9c8044",
                "font-weight": "500",
                "font-size": "24px"
              }
            },
            [_vm._v("捐款資訊")]
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("span", { staticClass: "sub-title" }, [
                  _vm._v("捐款項目：福智佛教學院")
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("span", { staticClass: "sub-title" }, [_vm._v("捐款方式")]),
                _vm._v(" "),
                _c("span", { staticClass: "required-mark" }, [_vm._v("*")]),
                _vm._v(" "),
                _c("span", { staticClass: "sub-title" }, [_vm._v(": ")])
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "donatorTypeCode" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.transferDonation.donatorTypeCode,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.transferDonation,
                                "donatorTypeCode",
                                $$v
                              )
                            },
                            expression: "transferDonation.donatorTypeCode"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: "PERSON" } }, [
                            _vm._v("個人捐款")
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "CORPORATION" } }, [
                            _vm._v("公司捐款")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("span", { staticClass: "sub-title" }, [_vm._v("捐款金額")]),
                _vm._v(" "),
                _c("span", { staticClass: "required-mark" }, [_vm._v("*")]),
                _vm._v(" "),
                _c("span", { staticClass: "sub-title" }, [_vm._v(":")])
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { staticStyle: { width: "fit-content", "margin-top": "8px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "amount" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          min: 100,
                          max: 10000000,
                          placeholder: "請輸入金額",
                          step: 100
                        },
                        model: {
                          value: _vm.transferDonation.amount,
                          callback: function($$v) {
                            _vm.$set(_vm.transferDonation, "amount", $$v)
                          },
                          expression: "transferDonation.amount"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticStyle: { width: "fit-content", "margin-top": "13px" } },
                [_c("span", { staticClass: "sub-title" }, [_vm._v(" 元")])]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "text-align": "center", margin: "16px 0" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "scroll-to",
                          rawName: "v-scroll-to",
                          value: "#step-two",
                          expression: "'#step-two'"
                        }
                      ],
                      on: {
                        click: function($event) {
                          return _vm.submitForm("transferDonation")
                        }
                      }
                    },
                    [_vm._v("下一步")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "dialog-message-box",
            title: _vm.dialog.title,
            visible: _vm.dialog.isShow,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog, "isShow", $event)
            }
          }
        },
        [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.dialog.content) } }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-row",
                { staticClass: "top-line" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "primary-color",
                          on: {
                            click: function($event) {
                              _vm.dialog.isShow = false
                            }
                          }
                        },
                        [_vm._v("我知道了")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }