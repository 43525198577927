var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "wp",
      attrs: { id: "body", "element-loading-text": "努力傳送資料中..." }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == "1",
              expression: "step == '1'"
            }
          ],
          attrs: { id: "step-one" }
        },
        [
          _c("CardDonationInfo", {
            on: {
              nextStep: function($event) {
                return _vm.setCardDonationInfo($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == "2",
              expression: "step == '2'"
            }
          ],
          attrs: { id: "step-two" }
        },
        [
          _c("CreditCardInfo", {
            attrs: { donationInfo: _vm.bwbcCardDonate },
            on: {
              nextStep: function($event) {
                return _vm.setCreditCardInfo($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == "3",
              expression: "step == '3'"
            }
          ],
          attrs: { id: "step-three" }
        },
        [
          _c("MemberInfo", {
            attrs: { donationInfo: _vm.bwbcCardDonate },
            on: {
              nextStep: function($event) {
                return _vm.setMemberInfo($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step == "4",
              expression: "step == '4'"
            }
          ]
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                "font-weight": "500",
                "font-size": "20px",
                color: "#9c8044"
              }
            },
            [_vm._v("感謝您進行了線上捐款")]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", [
            _c(
              "span",
              { staticStyle: { "font-size": "18px", color: "#9c8044" } },
              [_vm._v("以下是您的捐款資訊：")]
            ),
            _vm._v(" "),
            _c("p", { staticStyle: { "font-size": "16px", margin: "0" } }, [
              _vm._v("捐款編號：" + _vm._s(_vm.donationNo))
            ]),
            _vm._v(" "),
            _c("p", { staticStyle: { "font-size": "16px", margin: "0" } }, [
              _vm._v("捐款人：" + _vm._s(_vm.bwbcCardDonate.name))
            ]),
            _vm._v(" "),
            _c("p", { staticStyle: { "font-size": "16px", margin: "0" } }, [
              _vm._v("手機號碼：" + _vm._s(_vm.bwbcCardDonate.cellPhone))
            ]),
            _vm._v(" "),
            _c("p", { staticStyle: { "font-size": "16px", margin: "0" } }, [
              _vm._v("捐款項目：福智佛教學院")
            ]),
            _vm._v(" "),
            _c("p", { staticStyle: { "font-size": "16px", margin: "0" } }, [
              _vm._v("捐款日期：" + _vm._s(_vm.donaDate))
            ]),
            _vm._v(" "),
            _vm.bwbcCardDonate.paymentToolCode == "R"
              ? _c("p", { staticStyle: { "font-size": "16px", margin: "0" } }, [
                  _vm._v("捐款方式：定期定額")
                ])
              : _c("p", { staticStyle: { "font-size": "16px", margin: "0" } }, [
                  _vm._v("捐款方式：單筆捐款")
                ]),
            _vm._v(" "),
            _c("p", { staticStyle: { "font-size": "16px", margin: "0" } }, [
              _vm._v("捐款金額：" + _vm._s(_vm.bwbcCardDonate.amount))
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.bwbcCardDonate.receiptTypeCode == "BY_TIME",
                    expression: "bwbcCardDonate.receiptTypeCode == 'BY_TIME'"
                  }
                ],
                staticStyle: { "font-size": "16px", margin: "0" }
              },
              [_vm._v("收據開立方式：單筆開立")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.bwbcCardDonate.receiptTypeCode == "ANNUAL",
                    expression: "bwbcCardDonate.receiptTypeCode == 'ANNUAL'"
                  }
                ],
                staticStyle: { "font-size": "16px", margin: "0" }
              },
              [_vm._v("收據開立方式：年開")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.bwbcCardDonate.receiptTypeCode == "UNWANTTED",
                    expression: "bwbcCardDonate.receiptTypeCode == 'UNWANTTED'"
                  }
                ],
                staticStyle: { "font-size": "16px", margin: "0" }
              },
              [_vm._v("收據開立方式：不需寄發")]
            ),
            _vm._v(" "),
            _vm.bwbcCardDonate.receiptTypeCode != "UNWANTTED"
              ? _c("p", { staticStyle: { "font-size": "16px", margin: "0" } }, [
                  _vm._v("收據抬頭：" + _vm._s(_vm.bwbcCardDonate.donatorName))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("p", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("再次誠摯感謝您！")
          ]),
          _vm._v(" "),
          _vm._m(1)
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "dialog-message-box",
            title: _vm.dialog.title,
            visible: _vm.dialog.isShow,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog, "isShow", $event)
            }
          }
        },
        [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.dialog.content) } }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-row",
                { staticClass: "top-line" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "primary-color",
                          on: {
                            click: function($event) {
                              _vm.dialog.isShow = false
                            }
                          }
                        },
                        [_vm._v("我知道了")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "font-size": "16px" } }, [
      _vm._v(
        "\n      謝謝您認同我們的教育理念，更為了教育環境盡一份力！\n      "
      ),
      _c("br"),
      _vm._v("若捐款有問題我們將主動跟您聯繫。\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "font-size": "16px" } }, [
      _vm._v("\n      若您有疑惑，歡迎您透過以下方式聯繫我們：\n      "),
      _c("br"),
      _vm._v("籌備處募款組辦公室電話02-7730-0016\n      "),
      _c("br"),
      _vm._v("福智佛教學院籌備處信箱：bwbc.po@blisswisdom.org\n      "),
      _c("br")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }