var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "address", attrs: { model: _vm.address, rules: _vm.rules } },
        [
          _vm.address.addressType == "TAIWAN"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "city",
                      staticClass: "star",
                      attrs: { label: "縣市", prop: "city" }
                    },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.cityOptions },
                        on: { change: _vm.getDistrict },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    {
                                      attrs: { value: undefined, disabled: "" }
                                    },
                                    [_vm._v("-- 請選擇 --")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1276038285
                        ),
                        model: {
                          value: _vm.address.city,
                          callback: function($$v) {
                            _vm.$set(_vm.address, "city", $$v)
                          },
                          expression: "address.city"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "district",
                      staticClass: "star",
                      attrs: { label: "鄉鎮市區", prop: "district" }
                    },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.districtOptions },
                        on: { change: _vm.getRoadList },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    {
                                      attrs: { value: undefined, disabled: "" }
                                    },
                                    [_vm._v("-- 請選擇 --")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1276038285
                        ),
                        model: {
                          value: _vm.address.district,
                          callback: function($$v) {
                            _vm.$set(_vm.address, "district", $$v)
                          },
                          expression: "address.district"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "road",
                      staticClass: "star",
                      attrs: { label: "路街村里", prop: "road" }
                    },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.roadOptions },
                        on: { change: _vm.getRoadZipCode },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    {
                                      attrs: { value: undefined, disabled: "" }
                                    },
                                    [_vm._v("-- 請選擇 --")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1276038285
                        ),
                        model: {
                          value: _vm.address.road,
                          callback: function($$v) {
                            _vm.$set(_vm.address, "road", $$v)
                          },
                          expression: "address.road"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-bottom": "0px" },
                      attrs: { type: "flex", align: "middle" }
                    },
                    [
                      _c("el-col", { staticClass: "font-size-title" }, [
                        _vm._v("郵遞區號")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { staticStyle: { "margin-top": "0px" } },
                    [
                      _c(
                        "el-col",
                        [
                          _vm.address.zipCode
                            ? _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: "warning",
                                    size: "medium",
                                    effect: "plain",
                                    hit: false
                                  }
                                },
                                [_vm._v(_vm._s(_vm.address.zipCode))]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 12, sm: 8 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-col",
                                { staticClass: "ad-small-input" },
                                [
                                  _c("el-input", {
                                    staticClass: "small-input",
                                    attrs: { maxlength: "20" },
                                    model: {
                                      value: _vm.address.lane,
                                      callback: function($$v) {
                                        _vm.$set(_vm.address, "lane", $$v)
                                      },
                                      expression: "address.lane"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-col", { staticClass: "ad-small-label" }, [
                                _vm._v("巷")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 12, sm: 8 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-col",
                                { staticClass: "ad-small-input" },
                                [
                                  _c("el-input", {
                                    attrs: { maxlength: "20" },
                                    model: {
                                      value: _vm.address.alley,
                                      callback: function($$v) {
                                        _vm.$set(_vm.address, "alley", $$v)
                                      },
                                      expression: "address.alley"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-col", { staticClass: "ad-small-label" }, [
                                _vm._v("弄")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 12, sm: 8 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-col",
                                { staticClass: "ad-small-input" },
                                [
                                  _c("el-input", {
                                    attrs: { maxlength: "20" },
                                    model: {
                                      value: _vm.address.subAlley,
                                      callback: function($$v) {
                                        _vm.$set(_vm.address, "subAlley", $$v)
                                      },
                                      expression: "address.subAlley"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-col", { staticClass: "ad-small-label" }, [
                                _vm._v("衖")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 12, sm: 8 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-col",
                                { staticClass: "ad-small-input" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "20",
                                      placeholder: "88-10"
                                    },
                                    model: {
                                      value: _vm.address.number,
                                      callback: function($$v) {
                                        _vm.$set(_vm.address, "number", $$v)
                                      },
                                      expression: "address.number"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-col", { staticClass: "ad-small-label" }, [
                                _vm._v("號")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 12, sm: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "mb-0" },
                            [
                              _c(
                                "el-col",
                                { staticClass: "ad-small-input" },
                                [
                                  _c("el-input", {
                                    attrs: { maxlength: "20" },
                                    model: {
                                      value: _vm.address.floor,
                                      callback: function($$v) {
                                        _vm.$set(_vm.address, "floor", $$v)
                                      },
                                      expression: "address.floor"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-col", { staticClass: "ad-small-label" }, [
                                _vm._v("樓")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 12, sm: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "mb-0" },
                            [
                              _c(
                                "el-col",
                                { staticClass: "ad-small-input" },
                                [
                                  _c("el-input", {
                                    attrs: { maxlength: "20" },
                                    model: {
                                      value: _vm.address.room,
                                      callback: function($$v) {
                                        _vm.$set(_vm.address, "room", $$v)
                                      },
                                      expression: "address.room"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-col", { staticClass: "ad-small-label" }, [
                                _vm._v("室")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "500",
                        color: "#494949"
                      }
                    },
                    [
                      _vm._v(
                        "備註：請含有○○之○的巷、弄、號地址，以○○-○方式輸入"
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "500",
                        color: "#494949"
                      }
                    },
                    [_vm._v("如：88之10號→88-10號")]
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "other" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "100", placeholder: "請輸入地址" },
                        model: {
                          value: _vm.address.other,
                          callback: function($$v) {
                            _vm.$set(_vm.address, "other", $$v)
                          },
                          expression: "address.other"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }